<template>
  <div class="payment-activity-container">
    <!-- {{searcParam}}     -->

    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vx-card class="custom-vx-card">
          <tabs
            :tabs-list="tabs"
            default-select="TX"
            @handleEvent="selectedPage = $event"
          ></tabs>
          <vs-table
            :data="transactions"
            class="pay-activity-table stripes"
            stripe="true"
          >
            <template slot="header">
<!--              <TranscationFilter :searcParam="searcParam"></TranscationFilter>-->
            </template>
            <template slot="thead">
              <vs-th>
                <div>
                  <div class="combo-box" @click="changeFilter('date')">
                    <label>Date</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        icon="arrow_drop_up"
                        :class="
                          searcParam.sortBy === 'date' &&
                          searcParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                      />
                      <vs-icon
                        icon="arrow_drop_down"
                        :class="
                          searcParam.sortBy === 'date' &&
                          searcParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <date-picker
                    v-bind:class="{ active: searcParam.date }"
                    @change="onChangeInput()"
                    valueType="format"
                    v-if="!filtersHidden"
                    v-model="searcParam.date"
                    lang="en"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    class="pay-date"
                    format="DD/MM/YYYY"
                  ></date-picker>
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('reference')">
                    <label>Reference</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        icon="arrow_drop_up"
                        :class="
                          searcParam.sortBy === 'reference' &&
                          searcParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                      />
                      <vs-icon
                        icon="arrow_drop_down"
                        :class="
                          searcParam.sortBy === 'reference' &&
                          searcParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'reference')"
                    v-if="searcParam.reference"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    vs-input
                    v-bind:class="{
                      active: searcParam.reference,
                      textBlue: searcParam.reference,
                    }"
                    v-model="searcParam.reference"
                    @change="onChangeInput()"
                  />
                </div>
              </vs-th>

              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('customer')">
                    <label>Customer</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        icon="arrow_drop_up"
                        :class="
                          searcParam.sortBy === 'customer' &&
                          searcParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                      />
                      <vs-icon
                        icon="arrow_drop_down"
                        :class="
                          searcParam.sortBy === 'customer' &&
                          searcParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'customer')"
                    v-if="searcParam.customer"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-bind:class="{
                      active: searcParam.customer,
                      textBlue: searcParam.customer,
                    }"
                    v-model="searcParam.customer"
                    @change="onChangeInput()"
                  />
                </div>
              </vs-th>

              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('transaction')">
                    <label>Transaction ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        icon="arrow_drop_up"
                        :class="
                          searcParam.sortBy === 'transaction' &&
                          searcParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                      />
                      <vs-icon
                        icon="arrow_drop_down"
                        :class="
                          searcParam.sortBy === 'transaction' &&
                          searcParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'transaction')"
                    v-if="searcParam.transaction"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-bind:class="{
                      active: searcParam.transaction,
                      textBlue: searcParam.transaction,
                    }"
                    v-model="searcParam.transaction"
                    @change="onChangeInput()"
                  />
                </div>
              </vs-th>

              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('amount')">
                    <label>Amount</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        icon="arrow_drop_up"
                        :class="
                          searcParam.sortBy === 'amount' &&
                          searcParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                      />
                      <vs-icon
                        icon="arrow_drop_down"
                        :class="
                          searcParam.sortBy === 'amount' &&
                          searcParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'amount')"
                    v-if="searcParam.amount"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.amount"
                    v-bind:class="{
                      active: searcParam.amount,
                      textBlue: searcParam.amount,
                    }"
                    @change="onChangeInput()"
                  />
                </div>
              </vs-th>

              <vs-th>
                <div>
                  <div class="combo-box" @click="changeFilter('statusType')">
                    <label>Status</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        icon="arrow_drop_up"
                        :class="
                          searcParam.sortBy === 'statusType' &&
                          searcParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                      />
                      <vs-icon
                        icon="arrow_drop_down"
                        :class="
                          searcParam.sortBy === 'statusType' &&
                          searcParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                      />
                    </div>
                  </div>
                  <vs-select
                    v-if="!filtersHidden"
                    class="w-auto"
                    @click="changeStatus($event)"
                    @change="onChangeInput()"
                    v-model="searcParam.statusType"
                    :multiple="true"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in statusTypes"
                      @click.native="checkValue(item.value)"
                    />
                  </vs-select>
                </div>
              </vs-th>
              <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                <filter-icon
                  size="1.5x"
                  class="custom-class"
                  :fill="filterIconColor.fill"
                  :stroke="filterIconColor.stroke"
                  @click="toggleFilter"
                ></filter-icon
              ></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.createdAt">
                  {{ moment(tr.createdAt) }}
                </vs-td>

                <vs-td :data="tr.reference">
                  {{ tr.reference }}
                </vs-td>

                <vs-td
                  :data="tr.paymentRequest ? tr.paymentRequest.payeeName : ''"
                >
                  {{ tr.paymentRequest ? tr.paymentRequest.payeeName : "" }}
                </vs-td>

                <vs-td :data="tr.transactionID">
                  {{ tr.transactionID }}
                </vs-td>
                <vs-td :data="tr.amount">
                  {{ formatCurrency(tr.amount) }}
                </vs-td>

                <vs-td :data="tr.paymentStatus">
                  <span :class="changeStatusColor(tr.paymentStatus)">
                    {{
                      tr.paymentStatus === "Refunded"
                        ? tr.refundedByUser.length > 0
                          ? "Refunded By " + tr.refundedByUser[0].fullName
                          : "Refunded"
                        : tr.paymentStatus
                    }}</span
                  >
                </vs-td>
                <vs-td :data="tr">
                  <a
                    @click="viewDetail(tr)"
                    class="underline cursor-pointer"
                    >View</a
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-row class="vs-row justify-between px-5 my-10 items-center">
              <div class="records-per-page flex items-center">
                <label class="w-full mb-0">Records per page </label>
                <vs-select
                  v-if="!filtersHidden"
                  class="w-auto per-pg"
                  v-model="searcParam.limit"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in limitValue"
                  />
                </vs-select>
              </div>

              <div>
                <vs-row>
                  <vPegination
                    :total="totalPage"
                    v-model="searcParam.pageNumber"
                    :page-count="totalPage"
                  ></vPegination>
                </vs-row>
              </div>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
    <!-- Transaction Detail PopUp -->
    <vs-popup
      class="holamundo"
      :title="''"
      :active.sync="transactionDetailPopup"
    >
      <h3 class="font-bold">Transaction details</h3>
      <vs-row class="mt-10">
        <vs-col vs-w="12">
          <div class="flex mb-5">
            <label for="payeeName" class="w-1/2">Payee name</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.payeeName
                  ? selectedTransaction.payeeName
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="payeePhone">Payee phone</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.payeePhone
                  ? selectedTransaction.payeePhone
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="payeeEmail">Payee email</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.payeeEmail
                  ? selectedTransaction.payeeEmail
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="amount">Amount</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.amount
                  ? "$" + selectedTransaction.amount
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="yourReference">Your reference</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.reference
                  ? selectedTransaction.reference
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="paymentPage">Payment Page</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.page ? selectedTransaction.page.title : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="txConfirm">TX confirm</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.paymentStatus
                  ? selectedTransaction.paymentStatus
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="txReference">TX reference</label>
            <span class="font-bold w-1/2"> Reference </span>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import { FilterIcon } from "vue-feather-icons";
import TranscationFilter from "../../../../components/custom-filter/TranscationFilter.vue";
import DatePicker from "vue2-datepicker";
import vPegination from "../../../components/pagination.vue";
import Tabs from "@/views/components/Tabs";

export default {
  components: {
    TranscationFilter,
    DatePicker,
    vPegination,
    FilterIcon,
    Tabs,
  },
  data() {
    return {
      tabs: [
        { key: "PR", text: "Payment Requests" },
        { key: "TX", text: "Transactions" },
      ],
      selectedPage: "TX",
      filtersHidden: true,
      searcParam: {
        pageNumber: 1,
        totalData: 0,
        limit: 50,
        date: "",
        reference: "",
        customer: "",
        transaction: "",
        amount: "",
        statusType: [""],
        pageList: ["all"],
        sortBy: "createdAt",
        sortDir: "desc",
      },

      transactionDetailPopup: false,
      selectedTransaction: "",
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      transactions: [],
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      statusTypes: [
        { text: "Show all", value: "all" },
        { text: "Pending", value: "Pending" },
        { text: "Completed", value: "Completed" },
        { text: "Expired", value: "Expired" },
        { text: "Cancelled", value: "Cancelled" },
        { text: "Success", value: "Success" },
        { text: "Failed", value: "Failed" },
        { text: "Schedule registered", value: "Schedule registered" },
        { text: "Schedule failed", value: "Schedule failed" },
        { text: "Schedule cancelled", value: "Schedule cancelled" },
        { text: "Resend", value: "Re-sent" },
      ],
    };
  },
  methods: {
    ...mapActions("transaction", ["fetchTransactionListByPartnerIdWithFilter"]),

    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: this.partnerId,
      };
      await this.$store
        .dispatch("listSetting/fetchListSettingByPage", payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.searcParam.limit;
        })
        .catch((err) => {
          
        });
    },
    selectAll() {
      const statuses = this.statusTypes.map((el) => el.value);
      this.searcParam.statusType = statuses;
    },
    checkValue(val) {
      // handle uncheck when all is selected
      if (this.searcParam.statusType.includes("all") && val !== "all") {
        this.searcParam.statusType.splice(
          this.searcParam.statusType.indexOf("all"),
          1
        );
      }

      // unselect show all
      if (this.searcParam.statusType.includes("all") && val == "all") {
        this.searcParam.statusType = [];
      }

      //select show all
      if (!this.searcParam.statusType.includes("all") && val == "all") {
        this.selectAll();
      }
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: this.partnerId,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {})
        .catch((err) => {
          
        });
    },
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    momentTime(date) {
      return moment(date).format("h:mm:ss");
    },
    async getTransactionList() {
      ///  `${partnerId}?page=${page}&limit=${limit}&sort=desc`
      const partnerId = this.partnerId;
      const data = {
        partnerId: partnerId,
        searcParam: this.searcParam,
      };
      this.$vs.loading();
      await this.fetchTransactionListByPartnerIdWithFilter(data)
        .then((result) => {
          this.transactions = result.data.data.docs;
          this.searcParam.totalData = result.data.data.pagination.total
            ? result.data.data.pagination.total
            : 0;

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          
        });
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    viewDetail(data) {
      this.$router.push({
        name: "staff-payment-requests-detail",
        params: { id: data.paymentRequestId },
      });
    },
    changeFilter(value) {
      this.searcParam.sortBy = value;
      this.searcParam.sortDir =
        this.searcParam.sortDir === "desc" ? "asc" : "desc";
    },

    changeStatus(event) {
      let showArray = this.statusType;
      //if(showArray.includes('all')){
      //this.statusType = ['all'];
      //}
      
    },
    onChangeInput() {},

    changeStatusColor(status) {
      let bgClass = "";
      if (status == "Expired" || status == "Cancelled") {
        bgClass = "badge danger";
      } else if (status == "Refunded") {
        // yellow
        bgClass = "badge warning";
      } else if (status == "Completed") {
        bgClass = "badge success";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },

    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden === true) {
        this.searcParam = {
          pageNumber: 1,
          totalData: 0,
          limit: 50,
          date: "",
          reference: "",
          customer: "",
          transaction: "",
          amount: "",
          statusType: [""],
          pageList: ["all"],
          sortBy: "createdAt",
          sortDir: "desc",
        };
      }
    },

    removeShowAll() {
      for (let i = 0; i < this.searcParam.statusType.length; i++) {
        if (this.searcParam.statusType[i] === "all") {
          this.searcParam.statusType.splice(i, 1);
        }
      }
    },

    resetInput(event, id) {
      if (id == "reference") {
        this.searcParam.reference = "";
      } else if (id == "amount") {
        this.searcParam.amount = "";
      } else if (id == "customer") {
        this.searcParam.customer = "";
      } else if (id == "transaction") {
        this.searcParam.transaction = "";
      }
    },
  },
  mounted() {
    this.getListSetting();
    this.getTransactionList();
  },
  watch: {
    selectedPage(val) {
      this.$emit("handlePage", val);
    },

    "searcParam.statusType"(val) {
      this.getTransactionList();
    },

    "searcParam.reference"(val) {
      this.getTransactionList();
    },

    "searcParam.customer"(val) {
      this.getTransactionList();
    },

    "searcParam.transaction"(val) {
      this.getTransactionList();
    },

    "searcParam.amount"(val) {
      this.getTransactionList();
    },

    "searcParam.date"(val) {
      this.getTransactionList();
    },

    "searcParam.pageNumber"(val) {
      this.getTransactionList();
    },
    "searcParam.limit"(val) {
      this.getTransactionList();
      this.saveListSetting(val);
    },
    "searcParam.pageList"(val) {
      this.getTransactionList();
    },
    "searcParam.sortDir"(val) {
      this.getTransactionList();
    },
    "searcParam.sortBy"(val) {
      this.getTransactionList();
    },
  },
  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: '#828282' };
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return ['admin','superadmin'].includes(this.user.userType.toLowerCase())
        ? this.user._id
        : this.user.partnerId;
    },
    totalPage: function () {
      return Math.ceil(this.searcParam.totalData / this.searcParam.limit);
    },
  },
};
</script>