<template>
  <div class="payment-activity-container">
    <vs-row class="items-start">
      <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
        <vx-card class="custom-vx-card" style="background: #f8f8f8">
          <vs-table :data="paymentrequests" class="pay-activity-table stripes no-row-border-table">
            <template slot="thead">
              <vs-th width="14%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('paymentRequestId')">
                    <label class="m-0 p-0 text-base font-medium">Request ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'paymentRequestId' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'paymentRequestId' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'paymentRequestId')" v-if="searcParam.paymentRequestId" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.paymentRequestId"
                    v-bind:class="{isFocus: searcParam.paymentRequestId, textBlue: searcParam.paymentRequestId}"
                    vs-input
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>

              <vs-th width="14%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('reference')">
                    <label class="m-0 p-0 text-base font-medium">Reference</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'reference' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'reference' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'reference')" v-if="searcParam.reference" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.reference"
                    v-bind:class="{isFocus: searcParam.reference, textBlue: searcParam.reference}"
                    vs-input
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th width="15%">
                <div>
                  <div class="combo-box" @click="changeFilter('date')">
                    <label class="m-0 p-0 text-base font-medium">Date sent</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'date' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'date' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <date-picker
                    v-model="searcParam.date"
                    class="pay-date"
                    v-if="!filtersHidden"
                    format="DD/MM/YYYY"
                    lang="en"
                    placeholder="DD/MM/YYYY"
                    type="date"
                    v-bind:class="{isFocus: searcParam.date, textBlue: searcParam.date}"
                    valueType="format"
                    @change="onChangeInput($event)"
                  ></date-picker>
                </div>
              </vs-th>

              <vs-th width="15%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('customer')">
                    <label class="m-0 p-0 text-base font-medium">Customer</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'customer' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'customer' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'customer')" v-if="searcParam.customer" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.customer"
                    v-bind:class="{isFocus: searcParam.customer, textBlue: searcParam.customer}"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th width="15%">
                <div>
                  <div class="combo-box" @click="changeFilter('product')">
                    <label class="m-0 p-0 text-base font-medium">Product</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'product' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'product' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <vs-select
                      v-if="!filtersHidden"
                      class="w-auto"
                      v-model="searcParam.product"
                      :multiple="true"
                      v-bind:class="{isFocus: searcParam.product, textBlue: searcParam.product}"
                  >
                    <vs-select-item
                        v-for="(item, index) in products"
                        :key="index"
                        :text="item.productName"
                        :value="item.productName"
                        @click.native="checkProduct(item.productName)"
                    />
                  </vs-select>
                </div>
              </vs-th>
              <vs-th width="13%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('amount')">
                    <label class="m-0 p-0 text-base font-medium">Amount</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'amount' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'amount' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'amount')" v-if="searcParam.amount" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.amount"
                    v-bind:class="{isFocus: searcParam.amount, textBlue: searcParam.amount}"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>

              <vs-th width="12%">
                <div>
                  <div class="combo-box" @click="changeFilter('statusType')">
                    <label class="m-0 p-0 text-base font-medium">Status</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'statusType' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'statusType' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <vs-select
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.statusType"
                    :multiple="true"
                    v-bind:class="{isFocus: searcParam.statusType, textBlue: searcParam.statusType}"
                    @change="onChangeInput($event)"
                    @click="changeStatus($event)"
                  >
                    <vs-select-item
                      v-for="(item, index) in statusTypes"
                      :key="index"
                      :text="item.text"
                      :value="item.value"
                      @click.native="checkValue(item.value)"
                    />
                  </vs-select>
                </div>
              </vs-th>
              <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                <filter-icon
                  size="1.5x"
                  :fill="filterIconColor.fill"
                  :stroke="filterIconColor.stroke"
                  @click="toggleFilter"
                ></filter-icon>
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                <vs-td :data="tr.paymentRequestId">{{ tr.paymentRequestId }}</vs-td>
                <vs-td :data="tr.reference">{{ tr.reference }}</vs-td>
                <vs-td :data="tr.createdAt">{{ moment(tr.createdAt) }}</vs-td>
                <vs-td v-if="tr.payeeName !== ''" :data="tr.payeeName">{{ tr.payeeName }}</vs-td>
                <vs-td v-else :data="tr.payeeName">{{ tr.payeePhone !== "" ? tr.payeePhone : tr.payeeEmail }}</vs-td>
                <vs-td :data="tr.paymentRequestId">{{ paymentRequestType(tr) }}</vs-td>
                <vs-td :data="tr.amount">{{ formatCurrency(tr.amount.$numberDecimal || tr.amount) }}</vs-td>
                <vs-td :data="tr.paymentStatus"><span :class="changeStatusColor(tr.paymentStatus)">{{ tr.paymentStatus }}</span></vs-td>
                <vs-td :data="tr" class="text-center">
                 <router-link
                    :to="{
                      name: 'staff-payment-requests-detail',
                      params: { id: tr._id },
                    }"
                    class="underline cursor-pointer"
                  >
                    View
                  </router-link>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-row class="justify-between my-5 items-center">
              <div class="records-per-page flex items-center">
                <label class="w-full mb-0">Records per page </label>
                <vs-select v-model="searcParam.limit" class="per-pg">
                  <vs-select-item v-for="(item, index) in limitValue" :key="index" :text="item.text" :value="item.value" />
                </vs-select>
              </div>
              <div>
                <vs-row>
                  <vPegination v-model="searcParam.pageNumber" :page-count="totalPage" :total="totalPage"></vPegination>
                </vs-row>
              </div>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import TableFilter from "../../../../components/custom-filter/TableFilter.vue";
import vPegination from "../../../components/pagination.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { FilterIcon } from "vue-feather-icons";
import Tabs from "@/views/components/Tabs";
export default {
  components: {
    TableFilter,
    DatePicker,
    Tabs,
    vPegination,
    FilterIcon,
  },
  name: "PaymentRequest",
  data() {
    return {
      filtersHidden: true,
      products: [
        {
          productName: "All",
        },
        {
          productName: "Pay now",
        },
        {
          productName: "Pay later",
        },
      ],
      searcParam: {
        pageNumber: 1,
        totalData: 0,
        limit: 50,
        date: "",
        reference: "",
        customer: "",
        product: [],
        paymentRequestId:"",
        requestType: "all",
        amount: "",
        statusType: [""],
        pageList: ["all"],
        sortBy: "createdAt",
        sortDir: "desc",
      },
      tabs: [
        { key: "PR", text: "Payment Requests" },
        { key: "TX", text: "Transactions" },
      ],
      selectedPage: "PR",
      paymentrequests: [],
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      requestTypes: [
        { text: "All", value: "all" },
        { text: "Pay Now", value: "pay-now" },
        { text: "Pay Later", value: "pay-later" },
      ],
      statusTypes: [
        { text: "Show all", value: "all" },
        { text: "Pending", value: "Pending" },
        { text: "Active", value: "Active" },
        { text: "Complete", value: "Completed" },
        { text: "Expired", value: "Expired" },
        { text: "Cancelled", value: "Cancelled" },
        { text: "Resend", value: "Re-sent" },
        { text: "Info-required", value: "Info-required" },
        { text: "Extended", value: "Extended" },
        { text: "Submitted", value: "Submitted" },
      ],
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["fetchPaymentRequestListByPartnerIdWithFilter"]),
    ...mapActions("paymentType", ["fetchPaymentType"]),
    ...mapActions("paymentPlans", ["fetchAllPlans"]),
    ...mapActions("listSetting", ["fetchListSettingByPage", "updatelistSetting"]),
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    async getAllPayLaterProducts() {
      await this.fetchAllPlans()
          .then((res) => {
            this.products.push(...res.data.data.docs);
          })
          .catch((err) => {

          });
    },

    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: this.partnerId,
      };
      await this.fetchListSettingByPage(payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.searcParam.limit;
        })
        .catch((err) => {

        });
    },
    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden === true) {
        this.searcParam = {
          pageNumber: 1,
          totalData: 0,
          limit: 50,
          date: "",
          reference: "",
          customer: "",
          requestType: "all",
          amount: "",
          statusType: [""],
          pageList: ["all"],
          sortBy: "createdAt",
          sortDir: "desc",
        };
      }
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: this.partnerId,
      };
      await this.updatelistSetting(payload)
        .then((res) => {})
        .catch((err) => {

        });
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    momentTime(date) {
      return moment(date).format("h:mm:ss");
    },
    async getPaymentRequestList() {
      this.$vs.loading();
      const partnerId = this.partnerId;
      const data = {
        partnerId: partnerId,
        searcParam: this.searcParam,
      };
      await this.fetchPaymentRequestListByPartnerIdWithFilter(data)
        .then((result) => {
          this.paymentrequests = result.data.data.docs;
          this.searcParam.totalData = result.data.data.pagination.total ? result.data.data.pagination.total : 0;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();

        });
    },
    showPaymentPopup(pageId) {
      this.selectedPageId = pageId;
      this.paymentRequestPopup = true;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    paymentRequestType(val) {
      if (val.requestOptions.includes("pay-later") && val.requestOptions.includes("pay-now")) {
        if (["Pending", "Cancelled", "Expired", "Info-required"].includes(val.paymentStatus)) {
          return "Pay now / Pay later";
        }
        let returnValue = "";
        if (!val.selectedPaymentPlan || val.selectedPaymentPlan === "now") {
          returnValue = "Pay now";
        } else {
          returnValue = val.selectedPaymentPlan.planName ? val.selectedPaymentPlan.planName : val.selectedPaymentPlan.productName;
        }
        return returnValue;
      } else if (val.requestOptions.includes("pay-now")) {
        return "Pay now";
      } else if (val.requestOptions.includes("pay-later")) {
        if (val.payLaterPlan.length !== 1) {
          return "Pay later";
        }

        return ["Active", "Completed", "Submitted", "Extended"].includes(val.paymentStatus) ? val.payLaterPlan[0].productName : "Pay later";
      } else if (val.requestType == "recurring") {
        return "Recurring";
      }
    },
    changeFilter(value) {
      this.searcParam.sortBy = value;
      this.searcParam.sortDir = this.searcParam.sortDir === "desc" ? "asc" : "desc";
    },
    changeStatus(event) {
      let showArray = this.statusType;
    },
    onChangeInput(val) {},
    checkProduct(val) {
      // handle uncheck when all is selected
      if (this.searcParam.product.includes("All") && val !== "All") {
        this.searcParam.product.splice(this.searcParam.product.indexOf("All"), 1);
      }

      // unselect show all
      if (val == "All") {
        if (this.searcParam.product.includes("All")) {
          this.selectAll();
        } else {
          this.searcParam.product = [];
        }
      }
    },
    checkValue(val) {
      // handle uncheck when all is selected
      if (this.searcParam.statusType.includes("all") && val !== "all") {
        this.searcParam.statusType.splice(this.searcParam.statusType.indexOf('all'), 1);
      }

      // unselect show all
      if (this.searcParam.statusType.includes("all") && val == "all") {
        this.searcParam.statusType = [];
      }

      //select show all
      if (!this.searcParam.statusType.includes("all") && val == "all") {
        this.selectAll();
      }
    },

    changeStatusColor(status) {
      let bgClass = "";
      if (["Expired", "Cancelled"].includes(status)) {
        bgClass = "badge danger";
      } else if (status == "Refunded") {
        // yellow
        bgClass = "badge warning";
      } else if (status == "Completed") {
        bgClass = "badge success";
      } else if (["Pending", "Re-sent"].includes(status)) {
        bgClass = "badge pending";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },

    selectAll() {
      const statuses = this.statusTypes.map(el => el.value);
      this.searcParam.statusType = statuses;
    },
    resetInput(event, id) {
      if (id == "reference") {
        this.searcParam.reference = "";
      } else if (id == "amount") {
        this.searcParam.amount = "";
      } else if (id == "customer") {
        this.searcParam.customer = "";
      } else if (id == "paymentRequestId") {
        this.searcParam.paymentRequestId = "";
    }
    },
  },
  mounted() {
    this.getListSetting();
    this.getAllPayLaterProducts();
    this.getPaymentRequestList();
  },
  watch: {
    selectedPage(val){
      this.$emit('handlePage',val)
    },
    "searcParam.pageNumber"(page) {
      this.getPaymentRequestList();
    },
    "searcParam.product"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.limit"(val) {
      this.getPaymentRequestList();
      this.saveListSetting(val);
    },
    "searcParam.date"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.reference"(val) {
      this.getPaymentRequestList();
    },

    "searcParam.customer"(val) {
      this.getPaymentRequestList();
    },

    "searcParam.requestType"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.amount"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.statusType"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.pageList"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.sortDir"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.sortBy"(val) {
      this.getPaymentRequestList();
    },
    "searcParam.paymentRequestId"(page) {
      this.getPaymentRequestList();
    },
  },
  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: '#828282' };
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return ['admin','superadmin'].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
    },
    totalPage: function () {
      return Math.ceil(this.searcParam.totalData / this.searcParam.limit);
    },
  },
};
</script>

<style scoped>
  .textBlue .mx-input {
    color: #3a67f4;
  }

  .iconClose {
    position: absolute;
    z-index: 100;
    right: 7px;
    top: 33px;
    color: #3a67f4 !important;
  }

  .boxBody {
    position: relative;
  }

  .dark {
    color: #050552 !important;
  }
</style>
