<template>
  <div class="w-full">
    <vs-row class="justify-between mb-10 items-center">
     <div>
        <vs-select
          class="selectExample"
          v-model="searcParam.pageList"
          >
          <vs-select-item :key="index" :value="item._id" :text="item.title" v-for="item,index in pagesList" />
        </vs-select>
     </div>
      <div>
       <vs-button v-round color="primary" @click="exportToCsv()" icon="get_app" type="filled">Export<span v-if="loadingText">ing</span></vs-button>
     </div>
    </vs-row>
    
    </div>
  </template>

<script>
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";

export default {
   components:{
      DatePicker
    },
  props:['searcParam'],
  data() {
   
    return {
      loadingText:false,
      

      pagesList:[],

      statusArrayAll:["pending","active","complete",'expired','cancelled'],
    };
    
  },


  methods: {
     ...mapActions("page",["fetchPagesByPartnerId"]),
     ...mapActions("transaction",["exportTransactionListByPartnerIdWithFilter"]),

     async getPagesBypartnerId(){
         const partnerId  =  JSON.parse(localStorage.getItem("user"))._id;
       await this.fetchPagesByPartnerId(partnerId).then((response)=>{
         this.pagesList = response.data.data;
         this.pagesList.push({title:"All templates & hosted pages", _id:"all"})
       
       }).catch((err)=>{{}})
     },

    changeFilter(value) {
      this.searcParam.sortBy = value;
      this.searcParam.sortDir = this.searcParam.sortDir === "desc" ? "asc" : "desc";

     
    },
 
    changeStatus(event){
    let showArray = this.statusType;
    //if(showArray.includes('all')){
      //this.statusType = ['all'];
    //}
    
  },

  async exportToCsv(){
     this.loadingText = true;
      const partnerId  =  JSON.parse(localStorage.getItem("user"))._id;
    const data = {
      partnerId:partnerId,
      searcParam:this.searcParam
    }
    
    await this.exportTransactionListByPartnerIdWithFilter(data).then((response)=>{
      this.downloadCsv(response.data.data);
    }).catch((err)=>{{}});
  },

   downloadCsv(val){
    const path = process.env.VUE_APP_API_URL;
    const filePath = 'uploads/exports/';
    window.location.href = path+filePath+val;
     this.loadingText = false;
  }


  },
  mounted(){
    this.getPagesBypartnerId();
  }
 
};
</script>
<style>

</style>
