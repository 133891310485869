<template>
  <div class="payment-activity-wrapper">
    <payment-request></payment-request>
  </div>
</template>
<script>
import moment from "moment";
import PaymentRequest from "@/views/pages/partners/paymentRequest/paymentRequest.vue";
import TransactionsList from "@/views/pages/partners/transaction/Transaction-new";
import Tabs from "@/views/components/Tabs";
export default {
  components: {
    Tabs,
    PaymentRequest,
    TransactionsList,
  },
  data() {
    return {
      selectedPage: "PR",
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
    };
  },
  methods: {
    getPartnerId() {
      return JSON.parse(localStorage.getItem("user"))._id;
    },
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
  },
  mounted() {},

  computed: {
    activeTab() {
      return this.selectedPage;
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>